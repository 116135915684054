import * as React from "react";
import ImagePreview from "../_components/ImagePreview/ImagePreview";

const MapPreview = () => {
    return (
        <div className="map">
            <ImagePreview
                src={'https://assets.xela.co/silicon-valley-classic/svm/web/campus-map-2022.png'}
            />
        </div>
    );
};

export default MapPreview;
