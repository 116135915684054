import * as React from "react";
import { useState } from "react";
import { saveAs } from 'file-saver';

import Layout from "../../_components/Layout/Layout";
import "./styles.scss";

import {
    TransformWrapper,
    TransformComponent
} from "react-zoom-pan-pinch";
import { useEffect } from "react";

export default function ImagePreview({src}) {
    const [safariAgent, setSafariAgent] = useState(false);

    useEffect(() => {
        setSafariAgent(
            typeof window !== "undefined"
                ? window.navigator.userAgent.indexOf("Safari") > -1 && (navigator.userAgent.indexOf("Chrome") === -1)
                : false
            );
    }, []);

    const downloadImage = () => {
        saveAs(src, 'mubadala-svc-event-map');
    };

    return (
        <div className="image-preview">
            <Layout title="MapPreview">
                {!!safariAgent && (
                    <React.Fragment>
                        <div className="tools">
                            <a onClick={(e) => downloadImage(e)} className="download-button">
                                <img src="/images/silicon-valley/download-map.png"/>
                            </a>
                        </div>
                                
                        <a href='https://assets.xela.co/silicon-valley-classic/svm/web/campus-map-2022.png' target="_blank">
                            <img src={src} alt="preview" />
                        </a>
                    </React.Fragment>
                )}

                {!safariAgent && (
                    <TransformWrapper>
                        {() => (
                            <React.Fragment>
                                <div className="tools">
                                    <a onClick={(e) => downloadImage(e)} className="download-button">
                                        <img src="/images/silicon-valley/download-map.png"/>
                                    </a>
                                </div>
                                <TransformComponent>
                                    <img src={src} alt="preview" />
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>

                )}
            </Layout>
        </div>
    );
}
